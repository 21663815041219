.dashboard {
    width: 100%;
    /* height: 100vh; */
    /* overflow: hidden; */
}

.learning-header {
    padding-left: var(--padding);
    padding-right: var(--padding);
}
.learning-header,.learning-nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.learning-nav .left-side {
    flex-direction: row;
    display: flex;
}
.learning-nav .left-side img {
    height: 75px;
    object-fit: cover;
}

.learning-nav .right-side {
    flex-direction: row;
    gap: 20px;
    flex-basis: auto;
}


.learning-nav .right-side .header-button-plans {
    /* background: linear-gradient(to right, #ff0000 0%, #ffff00 50%, #00ff00 100%); */
    background-color: var(--secondary-color);
    background-size: 200% 100%;
    animation-name: change-color; /* name of the animation */
    animation-duration: 3s; /* duration of the animation */
    animation-iteration-count: infinite; /* repeat the animation infinitely */
    animation-direction: alternate; /* alternate between the initial and new colors */
    color: white;
    text-transform: capitalize;
    padding-inline: var(--padding);
  }
  
  @keyframes change-color {
    0% {
        background-position: left;
      }
      100% {
        background-position: right;
      }
  }

.learning-user-info {
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.learning-user-info .user-info {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.learning-user-info .credits-left {
    color: var(--description-color);
    font-size: 0.7rem;
    font-weight: 700;
}

.learning-text-info {
   text-align: right;
}
.learning-user-info .user-name {
    font-size: 1rem;
    font-weight: 500;
}

.learning-user-info .user-plans {
    font-size: 0.9rem;
    color: var(--description-color);
}

.learning-user-info .profile-picture {
    width: 50px;
    height: 50px;
    border-radius: var(--border-radius);
}

/* ////////////////////////////////////////////////////////////////////////////////// */

/* POPOVER */

.popover-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.popover-content .top-section {
    padding: var(--padding);
    padding-bottom: calc(var(--padding) / 2);
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255,0.3);
    color: white;
}

.popover-content .top-section .popover-email {
    color: var(--description-color);
    font-size: 0.9rem;
    margin-bottom: 20px;
    margin-top: -3px;
}

.popover-content .top-section .plans-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popover-content .top-section .plans-information  button {
    background-color: white ;
    color: black;
    text-transform:capitalize 
}

.popover-content .menu {
    width: 100%;
    padding: var(--padding);
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid rgba(255, 255, 255,0.3);
}
.popover-content .menu .menu-action {
    width: 100%;
    /* background-color: var(--card-background); */
}

.popover-content .menu .menu-action .menu-button {

    background-color: var(--card-lighter-background);
    color: white;
    text-transform:capitalize; 
    width:100%;
    display: flex;
    justify-content: flex-start;
}

.bottom-section .log-out-container, .upper-section .upgrade-button {
    width: 100%;
    padding: var(--padding);
    background-color: var(--card-background);
}
.bottom-section #log-out, .upgrade-container .upgrade-button {
    background-color: white;
    color: var(--error-color);
    text-transform:capitalize; 
    width:100%;
    display: flex;
    justify-content: flex-start;
    background-color: var(--card-lighter-background);
}
.upgrade-container .upgrade-button {
    color:  var(--succes-color);
}

.custom-paper {
    width: 350px;
    background-color: var(--card-background);
    box-shadow: var(--card-shadow);
}

.top-section .icon-profile {
    color: white;
    opacity: 0.7;
}
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////// */


/* PROFILE DIALOG  */


.profile-top {
    background-color: var(--primary-color);
    width: 100%;
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.profile-photo {
    border-radius: 50%;
    height: 100%;
    
}

.icon-profile {
    position: absolute;
    top: 5px;
    right: 5px ;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-bottom {

    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom:var(--padding) ;
    padding-left: calc(2 * var(--padding));
    padding-right: calc(2 * var(--padding));
    gap: 25px;
}

.profile-bottom .username {
    color: white;
}
.profile-bottom .email-container {
    display: flex;
    flex-direction: column;
}

.email-container {
    width: 100%;
}
.email-container .label {
    font-size: 0.9rem;
    margin-left:5px;
    color:white
}

.email-container .email-information {
    color: white;
    background-color: var(--card-lighter-background);
    border-radius: var(--border-radius);
    padding-inline: calc(var(--padding) / 2);
    cursor: pointer;
    width: 100%;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    transition: all 0.2s ease;
}

.email-container .email-information:hover {
    filter: brightness(1.2);
}

.profile-bottom .membership , .profile-bottom .credit {
    align-self: flex-start;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.credit .description {
    font-size: 0.7rem;
    color: var(--description-color);
}
.membership .membership-plan {
    color: white;
    width: 100%;
    text-transform:capitalize;
    background-color: var(--card-lighter-background);
    border-radius: var(--border-radius);
}

.credit .select {
    width: 100%;
    background-color: var(--card-lighter-background);
}
.mui-menu-item {
    background-color: var(--card-background);
}
.profile-bottom .delete-button {
    color: var(--error-color);
    width: 100%;
    font-size: 0.8rem;  
    text-transform: capitalize;
    
}

.profile-bottom-detail {
    color: white;
    font-size: 0.7rem;
    display: flex;
    gap: 10px;
    opacity: 0.8;
}
.profile-bottom-detail a {
    color: var(--description-color);
}
.profile-bottom-detail a:hover {
    text-decoration: underline;
}

.save-language {
    font-size: 0.7rem;
    background-color: var(--card-lighter-background);
    color: white;
    width: 50%;
    align-self: center;
    cursor:pointer;
    transition: all 0.2s ease;
}

.save-language:hover {
    filter: brightness(1.2);
}


/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */



/* CATEGORY */


.main-learning {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 15px;
    width: 100%;
}

.main-learning h1 {
    color: white;
    font-size: 2.5rem;
}
.categories {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 50px;  

    width: 100%;
    padding: var(--padding);
    max-width: 1600px;
}

.main-container {
    height: auto;
}

/* .categories .upper-section {
    display: flex;
    justify-content: space-between;

}

.categories .upper-section h2 {
    color: var(--description-color);
    font-weight: 400;
}

.categories .upper-section .arrow {
    transform: rotate(180deg);
    color: black;
} */

.category-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap:35px;
}

.category-list li {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding: calc(var(--padding) / 2);
    border-radius: var(--border-radius);
    transition: all 0.3s ease;
}

.category-list li:hover {
    background-color: #ddd;
}

.category-open {
    background-color: #ccc;
}
.center-item {
    width: 100%;
    align-items: center;
    justify-content: center;
}

.center-item .fa-solid {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */




/* PLAN GENERATOR */


.plan-generator {
    padding: var(--padding);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 400px;
    border-right: 1px solid var(--description-color);
    background-color: #ddd;
}

.plan-main-description .plan-category-title {
    font-size: 1.2rem;
}

.plan-main-description p {
    color: var(--description-color);
    font-size: 0.8rem;
    font-weight: 500;
}
.needed-information {
    display: flex;
    flex-direction: column;
    gap:30px;
}
.needed-information .main-information,.needed-information .time-period {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 500;
}



.main-information span {
    font-size: 0.8rem;
    color: var(--description-color);
}

.time-inputs {
    display: flex;
    align-items: center;
    gap: 15px;
}



/* .time-inputs .my-select:focus {
    border: 1px solid var(--primary-color);
} */

.more-info label {
    font-weight: 500
}
.more-info .free-plan-div {
    width: 100%;
    border: 1px solid var(--primary-color);
    height: 150px;
    font-size: 1rem;
}
.more-info .free-plan-div {
    background-color: white;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.more-info .free-plan-div i {
    color: var(--primary-color);
    font-size: 3rem;
    cursor: pointer;
}
.more-info span {
    font-size: 0.9rem;
    color: var(--error-color);
}
.plan-generator .generate-plan-button {
    background-color: var(--primary-color);
    font-size: 1.1rem;
    color: black;
    text-transform: capitalize;
}

.plan-generator .generate-plan-button:hover {
    background-color: var(--button-hover);
}

.plan-generator .generate-plan-button:active {
    background-color: var(--button-active);
}

.plan-generator .inactive-button {
    background-color: #bbb;
}


.plan-generator .inactive-button:hover,.plan-generator .generate-plan-button:active {
    background-color: #bbb;
}

.error {
    color: var(--error-color);
    font-size: 0.9rem;
}


.no-category {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.upgrade {
    align-self: center;
    width: 75%;
    margin-top: auto;
    margin-bottom: 100px;
    background-color: #ddd;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--padding);
    text-align: center;
    height: 200px;
    gap: 15px;
}

.upgrade p {
    font-size: 0.8rem;
    color: var(--description-color);
}
.upgrade i {
    font-size: 1.2rem;
}
.upgrade .upgrade-button {
    color: var(--description-color);
    border: 1px solid black;
    border-radius: calc(var(--border-radius) * 4);
    padding-inline: var(--padding);
    text-transform: capitalize;
}

/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

.more-credits {
    background-color: var(--card-background);
    color: white;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.color {
    color: var(--primary-color);
    font-weight: 700;
}

.more-credits .buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.more-credits .buttons button, .submit-suggestion {
    border: 1px solid var(--primary-color);
    background-color: var(--card-lighter-background);
    color: white;
    cursor: pointer;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    width: 100%;
}

.more-credits .buttons button:hover, .submit-suggestion:hover {
    filter: brightness(1.2);
}
.more-credits .buttons .credits {
    color: var(--primary-color);
    font-weight: 700;
    background-color: var(--card-background);
    border-radius: var(--border-radius);
    padding: 5px;
}
.secondary-text {
    font-size: 0.75rem;
    color: var(--description-color);
}
.buttons .upgrade-premium {
    animation: infinite 2s pulse;
}
.buttons .upgrade-premium:hover {
    animation: none;
}

@keyframes pulse {
    0% {
        filter: brightness(1);
    }

    50% {
        filter: brightness(1.2);
    }

    100% {
        filter: brightness(1);
    }
}
/* //////////////////////////////////////////////////////////// */


.suggestion-textarea {
    background-color: var(--card-lighter-background);
    border: 1px solid var(--primary-color);
    color: white;
    height: 300px;
}   
@media screen and (max-width: 1200px)
{   
    .categories {
        grid-template-columns: repeat(3, auto);
    }
}
@media screen and (max-width: 999px) {

    .categories {
        grid-template-columns: repeat(2, auto);
    }
   
    .plan-generator {
        width: 100%;
    }
    .main-learning {
        flex-direction: column;
        overflow-y: auto;
        height: auto;
        justify-content: center;
    }

   
    .plan-header {
        border-top: 1px solid var(--description-color);
        border-right: 1px solid var(--description-color);
    }

    .plan {
        gap: 10px;
        height: 600px;
    }
    .plan textarea {
        margin-bottom: 25px;
    }
    .no-category {
        margin-top: 250px;
    }

    .more-info {
        display: flex;
        flex-direction: column;

    }
}

@media screen and (max-width: 576px) {

    .main-learning {
        text-align: center;
    }
    .main-learning h1 {
        font-size: 2rem;
    }
    .categories {
        display: flex;
        flex-direction: column;
    }
    .plan {
        height: 400px;
    }
    .plan-header button {
        width: 50%;
    }
}

@media screen and (max-height: 700px) {
    .dashboard {
        overflow-y: scroll;
    }
}